import React from 'react'
import styled from 'styled-components'
import { gql } from '@apollo/client'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { ColumnFeatureFragment } from './__generated__/columnFeature'
import { html } from '../../../utils/html'
import Image from 'next/image'
import { Grid } from '@material-ui/core'

const StyledColumnFeature = styled(Grid)`
  padding-bottom: ${props => props.theme.typography.pxToRem(61)};
  ${props => props.theme.breakpoints.up('sm')} {
    padding-bottom: ${props => props.theme.typography.pxToRem(157)};
  }

  > div:last-child {
    padding: ${props => props.theme.typography.pxToRem(20)};
    ${props => props.theme.breakpoints.up('sm')} {
      padding: auto;
    }
  }
`

export type ColumnFeatureProps = {
  data: ColumnFeatureFragment[]
}

export const ColumnFeature: React.FC<ColumnFeatureProps> = ({ data }) => {
  if (!data[0]) {
    return null
  }

  const { leftColumn, rightColumn } = data[0]

  return (
    <StyledColumnFeature container direction="row" alignItems="center" spacing={8}>
      <Grid item xs={12} md={5}>
        {leftColumn && html(leftColumn)}
      </Grid>
      <Grid item md={7}>
        {rightColumn?.[0]?.url && rightColumn[0]?.__typename === 'images_Asset' && (
          <Image height={583} width={799} src={rightColumn[0].url} alt={''} />
        )}
      </Grid>
    </StyledColumnFeature>
  )
}

export const COLUMN_FEATURE_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  
  fragment ColumnFeatureFragment on columnFeature_BlockType {
    leftColumn
    rightColumn {
      ...ImageFragment
    }
  }
`